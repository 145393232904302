<template>
	<div class="a-timeline-flights">
		<div class='a-timeline-item'>
			<div class='a-timeline-flights-content' v-for='(itinerary, i) in item.Itineraries' :key='i'>
				<a-flight-card-item
					:item='item'
					:itinerary='itinerary'
				/>
			</div>
		</div>
		<a-timeline-markup
			:item='item'
			:price="$helpers.amount(item.TotalFare.Amount, item.TotalFare.CurrencyCode)"
			:result='true'
			:pdf='true'
		/>
	</div>
</template>

<script>
	import AFlightCardItem from '@/views/account/search/flights/card/item';
	import ATimelineMarkup from '@/views/account/itinerary/markup';

	export default {
		props: ['item', 'searchData'],
		components: {
			AFlightCardItem,
			ATimelineMarkup
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-flights {
		&-item {
			&-footer {
				border-top: 1px solid rgba($black, .1);
				&-details {
					padding: 10px 15px;
					@include minw( $grid-breakpoints-xs ) {
						padding: 8px 40px;
						min-height: 35px;
					}
				}
			}
		}
		&-content {
			padding: 15px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 35px * .6 30px * .6 25px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 35px * .8 30px * .8 25px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 35px 30px 25px;
			}
		}
		&-stop {
			display: flex;
			align-items: flex-start;
			color: rgba($orange, .8);
			padding: 20px 0 20px 16px;
			@include minw( $grid-breakpoints-xs ) {
				padding: 30px 0 30px 35px;
			}
			&-line {
				position: absolute;
				top: 0;
				bottom: 0px;
				width: 2px;
				left: 23px;
				background-color: rgba($cyprus, .15);
				mask-image: url("../../../../assets/line.svg");
				mask-repeat: repeat-y;
				@include minw( $grid-breakpoints-xs ) {
					left: 45px;
				}
			}
			&-icon {
				margin-top: -1px;
				flex-shrink: 0;
				margin-right: 20px;
				background-color: $white;
				@include minw( $grid-breakpoints-xs ) {
					margin-right: 45px;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					.app-icon {
						width: 16px !important;
						height: 16px !important;
					}
				}
			}
			&-content {
				display: flex;
				align-items: baseline;
			}
			&-number,
			&-title {
				font-size: 13px;
				font-weight: 400;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 16px;
				}
			}
			&-number {
				flex-shrink: 0;
				margin-right: 15px;
				@include minw( $grid-breakpoints-xs ) {
					margin-right: 26px;
				}
			}
		}
	}
</style>
